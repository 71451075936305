import React from "react";

const Redirect = () => {
  return (
    <div>
      <h1>OAuth Redirect Page</h1>
      <p>This page handles OAuth redirection for your application.</p>
    </div>
  );
};

export default Redirect;
